:root {
	--brand-red: #b01c2e;
	--dark-grey: #3d3838;
	--light-grey: #D3D3D3;
}

html {
	font-family: "Raleway", sans-serif;
	background: white;
	font-size: 12px;
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

details > summary {
  list-style: none;
}

details > summary::marker,
details > summary::-webkit-details-marker {
	display: none;
}

body.menu--open {
	overflow: hidden;
}

strong {
	color: #1a1a1a;
	font-weight: 700;
}

img {
	max-width: 100%;
	height: auto;
}

p, li {
	color: #3d3838;
	color: var(--dark-grey);
	line-height: 1.35;
}

p {
	max-width: 120ch;
	margin-right: auto;
	margin-left: auto;
}

figure, blockquote {
	margin: 0;
	padding: 0;
}

cite {
	font-style: normal;
}

.section-header {
	color: #b01c2e;
	color: var(--brand-red);
	font-weight: 400;
	text-align: center;
}

.section-subhead {
	letter-spacing: 0.15ch;
	font-weight: 800;
	text-align: center;
	text-transform: uppercase;
}

.subsection-subhead {
	font-weight: 800;
	text-align: center;
	font-size: 200%;
}

.subsection-subhead--copy-below {
	margin-bottom: 0;
}

.subsection .grid .subsection-subhead,
.subsection .grid .section-body {
	margin-top: 0;
	padding-top: 0;
}

.subsection .grid .section-body {
	padding-bottom: 1.5em;
}

.subsection-subhead--copy-below + p {
	margin-top: 0;
}

.section-header--inverse {
	color: white;
	text-shadow: 1px 1px 1px rgba(0,0,0,0.25);
}

.grid--project {
	max-width: 900px;
	margin: 0 auto;
	padding: 0 2rem;
}

.subsection--news {
	max-width: 1200px;
	margin: 0 auto;
}

.section-body {
	padding: 1em 2.5em;
	line-height: 1.5;
	text-align: center;
}

.section-body--narrowed {
	padding-top: 0;
	padding-bottom: 0;
}

.section-body > p {
	line-height: 1.5;
}

.section-body--first {
	font-size: 1.5em;
	padding: 1em;
	text-wrap: pretty;
}

.subsection > .section-header {
	font-size: 3em;
	line-height: 1.2;
	margin-bottom: 2rem;
}

.subsection > .section-header--cares {
	font-size: 1em;
	margin-bottom: 0;
	margin-top: 0.25em;
}

.util--anchor {
	position: absolute;
	top: -6em;
	height: 1px;
	width: 1px;
}

.util--anchor-mgmt {
	top: -1.25em;
}

.section-header--mgmt {
	position: relative;
}

.btn {
	display: inline-block;
	background-color: #b01c2e;
	background-color: var(--brand-red);
	min-width: 15ch;
	padding: 1em;
	border: 0;
	color: white;
	transition: background-color .2s;
	font-weight: bold;
	text-decoration: none;
	text-align: center;
	white-space: nowrap;
}

p .btn {
	min-width: 25ch;
}

.btn:hover,
.btn:focus,
.social-btn:hover,
.social-btn:focus {
	background-color: #3d3838;
	background-color: var(--dark-grey);
}

.btn:active {
	background-color: black;
}

.hero {
	min-height: 180px;
	background-color: #999;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	position: relative;
}

.hero--image {
	min-height: 50vh;
	max-height: 70vh;
	object-fit: cover;
}

.hero--hidden-image {
	display: none;
}

.hero--planning .hero--image {
	object-position: center 20%;
}

.hero__carousel {
	padding: 0;
	margin: 0;
	list-style: none;
	overflow: hidden;
	max-height: 1.15em;
}

.people__carousel--wrap {
	max-width: 100%;
	overflow: hidden;
	display: block;
}

[data-controller="carousel"],
[data-controller="odometer"] li {
	will-change: transform;
}

.people__carousel {
	padding: 0;
	margin: 0;
	list-style: none;
	display: flex;
}

.people__carousel li {
	flex: 0 0 auto;
	display: inline-block;
	margin-right: 1em;
}

.carousel__figure img {
	width: auto;
	height: 50vh;
	display: block;
	max-height: 768px;
}

.hero__carousel li {
	line-height: 1.15;
}

.project__image,
.person__image,
.project-category__image {
	background-color: #999;
}

.person__linkedin svg {
	width: 18px;
	height: 18px;
}

.person__linkedin:hover svg {
	fill: var(--brand-red);
}

.project {
	text-decoration: none;
}

.project:visited {
	color: inherit;
}

.person {
	text-align: center;
	display: block;
	text-decoration: none;
}

.grid--all-projects .person {
	margin-bottom: 1em;
}

.person p {
	padding: 0 1em;
	text-wrap: pretty;
}

.project__image img,
.project-category__image img {
	display: block;
	aspect-ratio: 16 / 9;
	object-fit: cover;
}

.person__image img {
	display: block;
	aspect-ratio: 1 / 1;
	object-fit: cover;
}

.person__image,
.project-category__image,
.project__image {
	position: relative;
	overflow: hidden;
}

.project__image--bottom-anchor img {
	transform-origin: bottom right;
}

.project__image img,
.project-category__image img,
.person__image--zoom:hover img {
	transition: transform 1.2s ease-in-out;
}

.project__image:hover img,
.project-category__image:hover img {
	transform: scale(1.2);
}

.person__image--zoom:hover img {
	transform: scale(1.1);
}

.person__image figcaption,
.project-category__image figcaption {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	background-color: rgba(0,0,0,0.4);
	text-transform: uppercase;
	color: white;
	letter-spacing: 0.3ch;
	font-weight: 800;
	font-size: 150%;
	padding: 0.3em 0;
}

.person__image details summary {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
}

.project-category__image figcaption {
	letter-spacing: 0.15ch;
	font-size: 120%;
	line-height: 1.5;
}

.person__image .person__name {
	font-size: 120%;
	font-weight: 400;
	display: block;
	text-transform: none;
	letter-spacing: 0;
}

.person__image .person__title {
	display: block;
	letter-spacing: 0.15ch;
	font-size: 70%;
	line-height: 1.5;
}

.benefits__body > p:first-child {
	margin-top: 0;
}

.benefits__list {
	margin-top: 0;
	padding: 0 0 0 1.25em;
}

.benefits__list li {
	margin: 0.25em 0 0.5em 0;
	line-height: 1.35;
}

.hero__text {
	width: 100%;
	position: absolute;
	bottom: 0;
}

.hero__text--careers {
	height: 100%;
}

@keyframes carousel {
	0% {
		opacity: 0;
		transform: translateY(15%);
	}
  2%, 24% {
    opacity: 1;
		transform: translateY(0);
  }
	25% {
		opacity: 0;
		transform: translateY(-15%);
	}
	100% {
		opacity: 0;
		transform: translateY(-15%);
	}
}

.hero .hero__text--carousel-item .section-subhead {
	font-size: 200%;
	margin-bottom: 0;
}

.hero__text--careers .hero__text--carousel-item {
	align-content: center;
	position: absolute;
	opacity: 0;
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
  animation: 
    carousel
		32s
    ease-out
    0s
    normal
    infinite
    none
    paused;
}

.hero__text--careers .hero__text--carousel-item,
.hero__text--careers--begin .hero__text--carousel-item {
	animation-play-state: running;
}

.hero__text--careers .hero__text--carousel-item:nth-child(2) {
  animation-delay: 8s;
}

.hero__text--careers .hero__text--carousel-item:nth-child(3) {
  animation-delay: 16s;
}

.hero__text--careers .hero__text--carousel-item:nth-child(4) {
  animation-delay: 24s;
}

.hero .section-header {
	font-size: 3em;
}

.hero .section-header {
	margin-bottom: 0.5em;
	text-shadow: 1px 1px 1px rgba(0,0,0,0.25);
}

.hero .section-subhead {
	margin-top: 0;
	margin-bottom: 2em;
	font-size: 1.25em;
}

.logo {
	flex: 0 0 12em;
	background: white;
	position: relative;
	z-index: 10;
}

.project .section-subhead {
	font-size: 150%;
	color: black;
	margin-top: 0.65em;
	margin-bottom: 0.25em;
}

.project .section-header {
	font-size: 200%;
	margin-top: 0;
	padding: 0 2em;
	margin-bottom: 1em;
}

.subsection--project {
	margin-bottom: 2em !important;
}

.subsection--project .section-header {
	margin: 0.5em auto;
	padding: 0 0.5em;
	max-width: 900px;
}

.main-navigation {
	position: sticky;
	width: 100%;
	top: 0;
	z-index: 10;
	background-color: white;
}

.main-navigation > .container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.5em 1em;
	width: 100%;
}

.main-navigation__trigger {
	background: white;
	position: relative;
	z-index: 10;
	cursor: pointer;
	border: 0;
	height: 32px;
	width: 36px;
	padding: 0;
	margin-top: 4px;
}

.main-navigation__trigger--hamburger {
	position: absolute;
	height: 4px;
	left: 6px;
	width: 24px;
	background-color: #b01c2e;
	background-color: var(--brand-red);
}

.main-navigation__trigger--hamburger-top {
	transition: transform .3s;
	top: 4px;
}

.main-navigation__trigger--hamburger-burger {
	transition: opacity .1s;
	top: 12px;
}

.main-navigation__trigger--hamburger-bottom {
	transition: transform .3s;
	top: 20px;
}

.mobile--expanded .main-navigation__trigger--hamburger-top {
	transform: rotate(-45deg);
	transform-origin: top right;
}

.mobile--expanded .main-navigation__trigger--hamburger-burger {
	opacity: 0;
}

.mobile--expanded .main-navigation__trigger--hamburger-bottom {
	transform: rotate(45deg);
	transform-origin: bottom right;
}

.main-navigation__nav {
	margin: 0;
	padding: 0.5em 0;
	list-style: none;
	justify-content: center;
	transform: translateY(-100%);
	transition: transform .4s ease;
	width: 100vw;
	overflow-y: scroll;
	overflow-x: hidden;
	background: white;
	position: fixed;
	top: 0;
	left: 0;
}

.mobile--expanded::after {
	content: "";
	position: absolute;
	top: 50px;
	left: 1em;
	width: calc(100% - 2em);
	height: 1px;
	background-color: #D3D3D3;
	background-color: var(--light-grey);
}

.mobile--expanded .main-navigation__nav {
	transform: translateY(42px);
	background: linear-gradient(to bottom, #fff, #f8f8f8 79px, #efefef);
	box-shadow: 0 2px 2px var(--dark-grey);
	box-shadow: 0 2px 2px rgba(61, 56, 56, 0.2);
}

.nav-item {
	margin: 0.5em 0;
	flex: 0 1 auto;
	font-size: 1.5em;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	cursor: pointer;
	padding: 0 1em;
	position: relative;
	visibility: hidden;
	opacity: 0;
	transform: translateY(-1em);
}

.mobile--expanded .nav-item:nth-child(1) {
	transition: visibility 0.1s linear 0.3s, opacity 0.3s ease 0.35s;
}

.mobile--expanded .nav-item:nth-child(2) {
	transition: visibility 0.1s linear 0.3s, opacity 0.305s ease 0.362s;	
}

.mobile--expanded .nav-item:nth-child(3) {
	transition: visibility 0.1s linear 0.3s, opacity 0.310s ease 0.374s;	
}

.mobile--expanded .nav-item:nth-child(4) {
	transition: visibility 0.1s linear 0.3s, opacity 0.315s ease 0.386s;
}

.mobile--expanded .nav-item:nth-child(5) {
	transition: visibility 0.1s linear 0.3s, opacity 0.320s ease 0.398s;
}

.mobile--expanded .nav-item:nth-child(6) {
	transition: visibility 0.1s linear 0.3s, opacity 0.325s ease 0.410s;
}

.mobile--expanded .nav-item:nth-child(7) {
	transition: visibility 0.1s linear 0.3s, opacity 0.325s ease 0.422s;
}

.mobile--expanded .nav-item {
	visibility: visible;
	opacity: 1;
	transform: translateY(0);
}

.nav-item__link {
	color: black;
	font-weight: 700;
	text-decoration: none;
	position: relative;
	z-index: 10;
	display: flex;
	align-items: center;
}

.nav-item__link--active-page {
	font-weight: bold;
	color: #b01c2e;
	color: var(--brand-red);
}

.nav-item__subnav .nav-item__link {
	font-weight: 400;
	font-size: 1rem;
	white-space: nowrap;
	display: none;
}

.main-navigation__subnav {
	padding: 0;
	overflow: hidden;
	height: 0;
	visibility: hidden;
	transform: translateY(-100vh);
	transition: transform .2s;
}

.main-navigation__subnav li {
	--delay: .1s;
	opacity: 0;
	transform: translateY(-0.2ch);
}

.nav-item:hover > .nav-item__link {
	color: #b01c2e;
	color: var(--brand-red);
}

.footer {
	margin-top: 3em;
	background: rgb(99, 100, 102);
}

.footer p {
	color: hsl(0, 0%, 90%);
	margin-left: 0;
	margin-right: 0;
}

.footer__navigation li {
	padding: 0;
	margin: 0 0 0.25em 0;
}

.footer__navigation a {
	color: white;
	font-weight: bold;
	text-transform: uppercase;
	text-decoration: none;
	font-size: 1em;
}

.footer__navigation a:hover,
.learn-more:hover {
	color: #b01c2e;
	color: var(--brand-red);
}

.footer > .container {
	padding: 1em 1em 2em 1em;
	text-align: center;
}

.footer__navigation {
	flex: 0 0 20ch;
	list-style: none;
	margin: 1em;
	padding: 0;
}

.footer__story {
	flex: 0 1 40ch;
	font-size: 90%;
	margin: 0 auto;
	max-width: 40ch;
}

.footer__address {
	flex: 0 1 30ch;
	margin: 0 4em;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.footer__address p:first-child {
	font-weight: bold;
	color: white;
}

.footer__links {
	margin: 1em 2em;
	text-align: center;
}

.footer__links .btn {
	display: inline-block;
	margin-bottom: 0.5em;
}

.values__figure {
	margin-bottom: 0.5em;
}

.values__list ul,
.hiring__list {
	list-style: none;
	padding: 0;
	margin-left: auto;
	margin-right: auto;
	width: 31ch;
}

.hiring__list {
	width: auto;
}

.values__list li,
.hiring__list li {
	font-weight: bold;
	line-height: 1.5;
	font-size: 1.5em;
	text-align: center;
	padding: 0;
}

.hiring__list li {
	margin: 1em;
}

.hiring__list,
.hiring__list li:last-child {
	margin-bottom: 0;
}

.hiring__list a {
	color: black;
	text-decoration: none;
}

.hiring__list a:hover {
	color: #b01c2e;
	color: var(--brand-red);
}

.careers__list--alternate {
	padding-left: 0;
	list-style: none;
}

.career-card__wrapper--alternate {
	border-bottom: 1px solid var(--light-grey);
	border-bottom: 0.5px solid var(--light-grey);
	padding: 1.5em 1em 1.5em 1em;
	margin: 0 auto;
	cursor: pointer;
	max-width: 1000px;
	position: relative;
}

.career-card__wrapper--alternate .career-card__title a::before {
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 0;
	top: 0;
	left: 0;
}

.career-card__wrapper--grid {
	align-items: center;
	display: flex;
	justify-content: space-between;
}

.career-card__wrapper--alternate .career-card__title a,
.career-card__wrapper--alternate .career-card__title a:visited {
	color: var(--brand-red);
}

.career-card {
	display: flex;
	flex-direction: column;
	text-align: center;
	gap: 1rem;
}

.career-card__wrapper--alternate:hover {
	background: linear-gradient(to bottom, #fafafa, #efefef);
}

.subsection .career-card__title {
	font-size: 150%;
	padding: 0;
	margin: 0;
}

.values__copy p,
.client--testimonial p,
.client--caption {
	font-size: 1.2em;
	line-height: 1.5;
	max-width: 60ch;
	padding: 0 2em;
}

.client--caption {
	padding-left: 1.75em;
}

.client--testimonial p {
	max-width: 100%;
}

.values__copy a {
	color: black;
	font-weight: bold;
	text-decoration: none;
}

.subsection {
	position: relative;
}

.subsection + .subsection {
	margin-top: 5em;
}

.subsection + .subsection::before {
	content: "";
	top: -2.25em;
	position: absolute;
	left: 5%;
	height: 1px;
	width: 90%;
	background-color: #D3D3D3;
	background-color: var(--light-grey);
	transition: 1s transform ease-out;
	transform: scale(0);
	transform-origin: center;
}

.subsection--contact {
	margin-bottom: 4em;
}

.subsection--contact:first-child {
	margin-top: 4em;
}

.subsection--contact + .subsection--contact::before {
	width: 100%;
	left: 0;
	top: -3.5em;
}

.subsection[data-aos="line-break"].aos-animate::before {
	transform: scale(1);
}

.subsection:last-child {
	margin-bottom: 4em;
}

.grid > .subsection:last-child {
	margin-bottom: 0;
}

.subsection--narrowed p {
	max-width: 90ch;
	margin-left: auto;
	margin-right: auto;
}

.section-body--narrowed p {
	max-width: 70ch;
	margin-left: auto;
	margin-right: auto;
}

.subsection--tighter p {
	max-width: 120ch;
	margin-left: auto;
	margin-right: auto;
}

.subsection--thin {
	max-width: 100ch;
	margin-left: auto;
	margin-right: auto;
}

.subsection h2,
.subsection h3,
.subsection h4 {
	padding-right: 1rem;
	padding-left: 1rem;
}

.subsection--contact h2,
.subsection--contact h3,
.subsection--contact h4,
.subsection--news h2,
.subsection--news h3,
.subsection--news h4 {
	padding-right: 0;
	padding-left: 0;
}

details > summary {
	height: 100%;
}

.five-values__value {
	height: 300px;
	max-width: 400px;
	background: #3d3838;
	background: var(--dark-grey);
	color: #D3D3D3;
	color: var(--light-grey);
	text-align: center;
	display: flex;
	justify-content: center;
	margin: 0 auto 1em auto;
}

.values--we-1 {
	background:  linear-gradient(to bottom, rgba(0,0,0,0.3), rgba(0,0,0,0.3) 100%), url("../img/values/voice-will-be-heard.jpg");
	background-size: cover;
}

.values--we-2 {
	background:  linear-gradient(to bottom, rgba(0,0,0,0.3), rgba(0,0,0,0.3) 100%), url("../img/values/We are not going anywhere 2.jpg");
	background-size: cover;
}

.values--dont {
	background: linear-gradient(to bottom, rgba(0,0,0,0.3), rgba(0,0,0,0.3) 100%),  url("../img/values/We dont just say it, we do it.jpg");
	background-size: cover;
}

.values--voice {
	background:  linear-gradient(to bottom, rgba(0,0,0,0.3), rgba(0,0,0,0.3) 100%), url("../img/values/Your voice will be heard.jpg");
	background-size: cover;
}

.values--customer {
	background: linear-gradient(to bottom, rgba(0,0,0,0.3), rgba(0,0,0,0.3) 100%), url("../img/values/Customer-Focused-2.jpg");
}

.values--integrity {
	background: linear-gradient(to bottom, rgba(0,0,0,0.3), rgba(0,0,0,0.3) 100%), url("../img/values/SiteVisit.jpg");
}

.values--family {
	background: linear-gradient(to bottom, rgba(0,0,0,0.3), rgba(0,0,0,0.3) 100%), url("../img/values/Family.jpg");
}

.values--quality {
	background: linear-gradient(to bottom, rgba(0,0,0,0.3), rgba(0,0,0,0.3) 100%), url("../img/values/Customer.jpg");
}

.values--enthusiasm {
	background: linear-gradient(to bottom, rgba(0,0,0,0.3), rgba(0,0,0,0.3) 100%), url("../img/values/estimating.jpg");
}

.five-values__value {
	background-position: center;
	background-size: cover;
	transition: background .5s;
	position: relative;
	color: white;
}

.promises .value--title {
	color: white;
}

.rollup::before {
	position: relative;
	top: 1.5em;
	margin: 1.5em;
	font-weight: bold;
	color: white;
}

.promises .rollup::before {
	content: none;
}

.promises .rollup p {
	/* reset of above from alternative values setups */
	margin-top: 1.25em;
}

.five-values__value .value--title {
	transition: transform .2s;
	align-self: center;
}

@keyframes moveup {
  0%    { transform: translateY(100%); }
  100%  { transform: translateY(0); }
}

@keyframes movedown {
  0%    { transform: translateY(0); }
  100%  { transform: translateY(100%); }
}

@keyframes fadein {
  0%    { opacity: 0; }
  100%  { opacity: 1; }
}

@keyframes fadeout {
  0%    { opacity: 1; }
  100%  { opacity: 0; }
}

.rollup p {
	padding: 0 1.5em;
	margin: 1.25em 0;
	font-size: 1.5em;
}

.box--has-rollup[open] summary ~ .rollup p {
	animation: fadein .2s ease-in-out 0.08s;
}

.box--has-rollup[open].rolldown summary ~ .rollup p {
	animation: fadeout .2s ease-in-out 0.08s;
}

.box--has-rollup[open] summary ~ .rollup {
	animation: moveup .35s ease-in-out;
}

.box--has-rollup[open].rolldown summary ~ .rollup {
	animation: movedown .35s ease-in-out;
}

.box--has-rollup {
	position: relative;
	background-position: bottom center;
	overflow: hidden;
}

.person .box--has-rollup {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
}

.box--has-rollup summary {
	display: flex;
	justify-content: center;
	align-items: center;
}

.rollup {
	text-align: left;
	height: 100%;
	width: 100%;
	transform: translateY(0);
	background: rgba(0,0,0,0.8);
	position: absolute;
	top: 0;
}

.five-values__value .rollup,
.grid--benefits .rollup {
	background: #b01c2e;
	background: var(--brand-red);
}

.grid--benefits summary {
	display: block;
}

.grid--benefits .rollup {
	display: flex;
	flex-direction: column;
}

.grid--benefits .rollup h4 {
	color: #eee;
	text-align: center;
	justify-content: center;
}

.grid--benefits .rollup li {
	color: #eee;
}

.five-values .rollup p {
	font-size: 1em;
}

.value--title {
	font-size: 1.6em;
	margin: 0 1em;
	font-weight: normal;
	align-self: center;
}

.five-values__value .value--text p {
	color: #D3D3D3;
	color: var(--light-grey);
}

.five-values__value .value--text strong {
	color: white;
}

.person--text p {
	text-align: left;
	color: #D3D3D3;
	color: var(--light-grey);
}

.person__linkedin {
	display: flex;
	gap: 0.75rem;
	align-items: center;
	font-size: 80%;
	text-decoration: none;
	color: #D3D3D3;
	color: var(--light-grey);
}

.person__linkedin:hover span {
	border-bottom: 1px solid var(--brand-red);
}

.promises .value--text p {
	text-align: left;
}

.differences {
	text-align: center;
}

.differences p {
	padding: 0 4em;
}

.differences--title {
	font-weight: 800;
	color: #b01c2e;
	color: var(--brand-red);
	text-transform: uppercase;
	font-size: 150%;
	letter-spacing: 0.2ch;
	max-width: 25ch;
	margin-left: auto;
	margin-right: auto;
}

.grid--testimonial {
	text-align: center;
	margin: 2em auto 0 auto;
}

.grid--testimonial + .grid--testimonial {
	border-top: 1px solid #b01c2e;
	border-top: 1px solid var(--brand-red);
	padding-top: 2em;
}

.client--caption cite {
	display: block;
}

.client--testimonial p {
	position: relative;
	font-style: italic;
	font-weight: 500;
}

.client--testimonial p::before {
	content: "“";
	color: #D3D3D3;
	color: var(--light-grey);
	font-family: Georgia, serif;
	position: absolute;
	margin-left: -1ch;
	margin-top: -0.5ch;
	font-size: 200%;
	font-weight: bold;
}

.client--testimonial p::after {
	content: "”";
	color: #D3D3D3;
	color: var(--light-grey);
	font-family: Georgia, serif;
	font-size: 200%;
	margin-top: -0.5ch;
	font-weight: bold;
	position: absolute;
}

.client--caption {
	position: relative;
	line-height: 1.5;
	color: #3d3838;
	color: var(--dark-grey);
}

.client--caption strong {
	color: black;
}

.client--caption::before {
	content: "-";
	color: #b01c2e;
	color: var(--brand-red);
	position: absolute;
	margin-left: -1ch;
}

.client--caption a {
	display: block;
	margin-top: 0.5em;
	color: #b01c2e;
	color: var(--brand-red);
	font-weight: bold;
	text-transform: uppercase;
	letter-spacing: 0.2ch;
	font-size: 80%;
}

.testimonial__logo-switch {
	position: relative;
}

.client--logo, 
.client--image {
	object-fit: contain;
	align-self: center;
	justify-self: center;
	padding: 1rem 2rem;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	transition: opacity .4s ease-out;
}

.client--image {
	opacity: 0;
}

.testimonial__logo-switch:hover .client--logo {
	opacity: 0;
}

.testimonial__logo-switch:hover .client--image {
	opacity: 1;
}

.client--logo[src$=".svg"] {
	transform: scale(0.75) translateY(-50%);
}

.footer__links--social {
	display: flex;
	justify-content: space-evenly;
	max-width: 300px;
	margin: 0 auto;
}

.social-btn {
	flex: 0 1 24px;
	padding: 8px 10px;
	margin: 4px;
	border-radius: 6px;
	background: black;
	display: flex;
	justify-content: center;
	align-content: center;
}

.social-btn svg,
.social-btn img {
	width: 20px;
	display: block;
	fill: white;
}

.social-btn img {
	max-width: 20px;
	width: 20px;
	height: auto;
	display: block;
}

.project__details {
	line-height: 1.5;
	grid-template-columns: 1fr 1fr;
	grid-gap: 0 2rem;
	gap: 0 2rem;
	grid-auto-flow: row;
	border-bottom: 1px solid #D3D3D3;
	border-bottom: 1px solid var(--light-grey);
	margin-bottom: 2rem;
}

.project__details dt {
	letter-spacing: 0.1ch;
	text-transform: uppercase;
	font-weight: 800;
	grid-column: 2 / 3;
}

.project__details dd {
	margin: 0 0 1em 0;
	grid-column: 2 / 3;
}

.project__details .project__details--highlights {
	grid-row: 1 / 2;
	grid-column: 1 / 2;
}

.project__details .project__details--highlights:last-child {
	grid-row: 2 / 8;
}

.project__highlights {
	padding: 0 0 0 1.25em;
	margin: 0;
}

.vimeo-wrapper {
	padding-bottom: 56.25%;
	position: relative;
	height: 0;
	overflow: hidden;
}

.vimeo-wrapper.aspect-43 {
	padding-bottom: 75%;
}

.vimeo-wrapper iframe {
	display: block;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.gallery-thumbnail__item {
	cursor: pointer;
}

.additional-media__item .vimeo-thumbnail {
	display: none;
}

.gallery {
	position: relative;
	margin: 0 auto;
}

.gallery-hero {
	position: relative;
	background: black;
}

.gallery-hero__title {
	position: sticky;
	left: 0;
	bottom: 0;
	color: white;
	background: #b01c2e;
	background: var(--brand-red);
	width: 100%;
	font-size: 75%;
}

.gallery-hero__title h1 {
	padding-top: 0.325em;
	padding-bottom: 0.325em;
	margin: 0;
}

.gallery-hero__image {
	display: block;
}

.gallery-hero__item {
	opacity: 0;
	min-height: 80px;
	transition: opacity .4s ease .2s;
}

.gallery-hero__item--image {
	transform: scale(1.15);
	transition: opacity 1.5s ease .2s, transform 3.5s ease .4s;
}

.gallery-hero__item--loaded,
.no-js .gallery-hero__item {
	opacity: 1;
	transform: scale(1);
}

.news-image {
	text-align: center;
}

.news-content {
	padding: 0 2rem 2rem 2rem;
}

.news-content .additional-media__item img {
	aspect-ratio: 3 / 2;
}

.subsection--contact {
	margin-top: 1em;
	text-align: center;
}

.contact__headers {
	font-size: 2em;
	margin-top: 0.15em;
}

.contact__pre-title {
	font-weight: 800;
	letter-spacing: 0.15ch;
	font-size: 60%;
	text-transform: uppercase;
	display: block;
	margin: 0;
}

.contact__title {
	font-size: 100%;
	font-weight: 400;
	display: block;
	color: #b01c2e;
	color: var(--brand-red);
	margin-top: 0;
}

.contact--actions {
	font-size: 80%;
	line-height: 1;
}

.contact__body {
	line-height: 1.5;
	font-size: 1.2em;
}

.contact__body--buttons {
	font-size: 0.8em;
}

.contact__body--buttons .btn {
	min-width: 20ch;
	width: 20ch;
}

.contact--actions a {
	color: black;
}

.archive--header {
	cursor: pointer;
	margin-left: 1em;
}

.archive--preview {
	margin-left: 2em;
}

.archive--header:hover {
	color: #b01c2e;
	color: var(--brand-red);
}

.archive--read-more {
	font-size: 90%;
	color: #3d3838;
	color: var(--dark-grey);
}

.archive--read-more:hover {
	color: #b01c2e;
	color: var(--brand-red);
}

.additional-media__item {
	margin: 1em 0;
	cursor: pointer;
}

.additional-media__item img {
	height: auto;
	width: 100%;
	display: block;
}

.grid--news {
	border-bottom: 1px solid #b01c2e;
  border-bottom: 1px solid var(--brand-red);
	margin-bottom: 2em;
}

.project-description ul {
	padding-left: 1.25em;
}

.project-description li,
.project__highlights li {
	margin: 0.25em 0 0.5em 0;
	line-height: 1.35;
}

.project__highlights li:last-child {
	margin-bottom: 1em;
}

.project__highlights--highlights li {
	color: black;
	margin-bottom: 0;
}

[data-odometer-target="word"].transitions-enabled {
	transition: transform 0.4s;
}

.breadcrumbs {
	margin-top: 2em;
	padding-bottom: 1em !important;
	text-align: center;
	color: #3d3838;
	color: var(--dark-grey);
}

.breadcrumbs a {
	color: #b01c2e;
	color: var(--brand-red);
}

.breadcrumbs a::after {
	content: "<<";
	margin-left: 1ch;
	margin-right: 1ch;
	display: inline-block;
	text-decoration: none;
}

.logo svg,
.logo img {
	height: auto;
	max-height: 50px;
	max-width: 100%;
	display: block;
}

.logo--black {
	fill: #231f20;
}

.logo--red {
	fill: #bf2033;
}

.logo--line {
	stroke: #231f20;
}

.hero::before,
.gallery-hero__item::before {
	content: "";
	opacity: 0.2;
	background: #000;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	pointer-events: none;
}

.hero--planning .gallery-hero__item::before {
	opacity: 0.5;
}

.grid--people {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 1em;
	gap: 1em;
	font-size: 6px;
}

.grid--leadership {
	grid-template-columns: repeat(4, 1fr);
}

.grid--leadership .person {
	grid-column: span 2;
}

.grid--leadership .person:last-child {
	grid-column: 2 / span 2;
}

.additional-media__item img {
	object-fit: cover;
	max-height: 200px;
	height: 100%;
	width: 100%;
}

.container {
	max-width: 1400px;
	margin: 0 auto;
}

.additional-media {
	max-width: 100%;
}

.career-wrap {
	max-width: 1000px;
	margin: 0 auto;
	padding-left: 1em;
	padding-right: 1em;
}

.career-wrap p {
	text-align: left;
	padding: 0;
}

.career-wrap h1 {
	color: var(--brand-red);
}

.career-wrap h2 {
	margin-top: 1.25em;
	margin-bottom: 0.25em;
}

.career-wrap h3 {
	margin-top: 1.5em;
	margin-bottom: 0.35em;
}

.career-wrap h4 {
	margin-top: 1.75em;
	margin-bottom: 0.15em;
}

.career-wrap h2 + h3 {
	margin-top: 0.5em;
}

.career-wrap h2 + p, .career-wrap h3 + p,
.career-wrap h2 + ul, .career-wrap h3 + ul,
.career-wrap h4 + p {
	margin-top: 0;
}

.grid--benefits {
	text-align: center;
}

.grid--benefits h4 {
	font-size: 150%;
}

.grid--benefits .rollup h4 {
	margin-bottom: 0;
}

.grid--benefits figure {
	padding: 0 4rem 2rem 4rem;
}

.grid--benefits svg {
	font-weight: bold;
	fill: var(--brand-red);
}

.grid--benefits .rollup > ul {
	margin-right: 1em;
}

/* no grid support */

@media all and (min-width: 440px) {
	.grid--four {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 1em;
		gap: 1em;
		padding-left: 1em;
		padding-right: 1em;
	}
	
	.grid--benefits .rollup > ul {
		font-size: 86%;
	}
}

@media all and (min-width: 600px) {
	
	.grid--benefits .rollup > ul {
		font-size: 100%;
	}

	.grid,
	.additional-media {
	  display: flex;
	  flex-direction: row;
	  flex-wrap: wrap;
	}
	
	.additional-media .additional-media__item {
		margin: 0.25em;
		min-width: calc(50% - 2em);
		width: calc((100% / 2) - 0.5em);
	}

	.grid > * {
		margin: 0.5em;
		min-width: calc(50% - 2em);
		width: calc((100% / 2) - 1em);
	}

	.grid--three > * {
		margin: 0.33em;
		min-width: calc(33% - 2em);
		width: calc((100% / 3) - 0.75em);
	}
	
	.grid--four > * {
		margin: 0.25em;
		min-width: calc(25% - 2em);
		width: calc((100% / 4) - 0.5em);
	}
	
	.grid--asym > *:first-child,
	.grid--asym-reverse > *:last-child {
		min-width: calc(33% - 2em);
		width: calc(33% - 2em);
	}

	.grid--asym > *:last-child,
	.grid--asym-reverse > *:first-child {
		min-width: calc(50% - 2em);
		width: calc(66% - 2em);
	}

	.subsection--contact > * {
		min-width: calc(50% - 2em);
		width: calc((100% / 2) - 1em);
	}
	
}

@media all and (min-width: 800px) {
	.footer > .container {
	  display: flex;
	  flex-direction: row;
	  flex-wrap: wrap;
	}

	.footer > .container > .footer__navigation {
		width: 25%;
		min-width: 100px;
	}

	.footer > .container > .footer__links {
		width: 20%;
		min-width: 188px;
		margin-left: auto;
	}

	.footer__address {
		margin-left: 0;
	}

	.footer > .container > .footer__story-and-address {
		width: 45%;
	}
}

@media all and (min-width: 1000px) {
	.additional-media  .additional-media__item {
		margin: 0.2em;
		min-width: calc(25% - 2em);
		width: calc((100% / 4) - 0.8em);
	}
}

@supports (display: grid) {
	
	.grid > *,
	.grid--three > *,
	.grid--four > *,
	.grid--asym > *:first-child,
	.grid--asym > *:last-child,
	.grid--asym-reverse > *:first-child,
	.grid--asym-reverse > *:last-child,
	.subsection--contact > *,
	.footer > .container > .footer__navigation,
	.footer > .container > .footer__links,
	.footer > .container > .footer__story-and-address,
	.additional-media .additional-media__item {
		margin: initial;
		min-width: initial;
		width: initial;
	}
	
	.additional-media .additional-media__item {
		margin: 1em 0;
	}
	
	.footer__address {
		margin: 0 4em;
	}
	
	.footer > .container > .footer__links {
		margin-left: initial;
	}
	
	.footer > .container > .footer__navigation {
		margin: 1em;
	}
	

	.footer > .container > .footer__links {
		margin: 1em 2em;
	}
}

@media all and (min-width: 375px) {
	.project__details {
		display: grid;
	}
	
	.hero .section-header {
		font-size: 3.5em;
	}
}

@media all and (min-width: 600px) {
	
	.grid--benefits .section-header,
	.grid--benefits .section-body {
		text-align: left;
		padding-left: 0;
	}
	
	.grid--benefits {
		margin-bottom: 3rem;
	}
	
	.additional-media {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 1rem;
		gap: 1rem;
		margin-bottom: 1rem;
	}
	
	.additional-media__item .vimeo-wrapper {
		display: none;
	}
	
	.additional-media__item .vimeo-thumbnail {
		display: inline;
	}
	
	.additional-media .additional-media__item {
		margin: 0;
	}

	.rollup p {
		padding: 0 1em;
		margin: 1em 0;
		font-size: 1em;
	}
	
	.rollup li {
		font-size: 1em;
	}
	
	html {
		font-size: 13px;
	}
	
	.subsection + .subsection {
		margin-top: 6.5em;
	}
	
	.subsection + .subsection--contact {
		margin-top: 0;
	}
	
	.subsection + .subsection::before {
		top: -3em;
	}
	
	.grid {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 1em;
		gap: 1em;
		padding-left: 1em;
		padding-right: 1em;
	}
	
	.grid--all-projects .person {
		margin-bottom: 0;
	}
	
	.grid--projects {
		grid-gap: 3em 1em;
		gap: 3em 1em;
	}
	
	.grid--three,
	.grid--four {
		grid-template-columns: repeat(2, 1fr);
	}
	
	.grid--leadership {
		grid-template-columns: repeat(4, 1fr);
	}
	
	.grid--people {
		font-size: inherit;
	}
	
	.grid--asym {
		grid-template-columns: 1fr 2fr;
	}
	
	.grid--asym-reverse {
		grid-template-columns: 2fr 1fr;
		gap: 3em;
	}
	
	.grid--project {
		/* padding: 0; */
	}
	
	.project__details {
		display: block;
	}
	
	.project__details dd {
		max-width: 20ch;
	}
	
	.subsection--contact {
		grid-template-columns: 30ch 1fr;
		grid-gap: 2em;
		gap: 2em;
		max-width: 76ch;
		margin-right: auto;
		margin-left: auto;
	}
	
	.contact__headers {
		text-align: left;
	}
	
	.contact__body {
		text-align: left;
		margin: 0;
	}
	
	.contact__body--buttons {
		margin-top: 2em;
	}
	
	.grid .subsection + .subsection--line-left::before {
		height: 90%;
		width: 1px;
		top: 2.25em;
		left: -0.5em;
	}
	
	.grid .subsection--line-right::before {
		height: 90%;
		width: 1px;
		top: 0;
		left: auto;
		right: -0.5em;
	}
	
	.grid .subsection + .subsection--line-below::before {
		top: auto;
		bottom: 0;
	}
	
	.grid .subsection--line-above::before {
		top: 0;
	}
	
	.grid--project .project-details {
		border-right: 1px solid #D3D3D3;
		border-right: 1px solid var(--light-grey);
		margin-right: 1em;
	}
	
	.project__details {
		border-bottom: 0;
		margin-bottom: 0;
	}
	
	.grid--news {
		grid-template-columns: minmax(250px, 1fr) 2fr;
		grid-gap: 2em;
		gap: 2em;
		margin: 2em auto;
    padding-bottom: 2em;
	}
	
	.grid--news[hidden] {
		display: none;
	}
	
	.news--header {
		margin-top: 0;
	}
	
	.person {
		margin-bottom: 0;
	}
	
	.grid--testimonial {
		grid-gap: 1em 0;
		gap: 1em 0;
		max-width: 1200px;
		text-align: left;
	}
	
	.grid--testimonial + .grid--testimonial {
		border-top: 0;
		padding-top: 0;
	}
	
	.project .section-header {
		margin-bottom: 0;
	}
	
	.testimonial__text {
		display: flex;
		flex-direction: column;
		align-self: center;
	} 
	
	.testimonial__logo-switch {
		float: left;
		display: block;
		max-width: 400px;
	}
	
	.testimonial:after {
		content: "";
		display: table;
		clear: both;
	}
	
	.values__list li {
		text-align: left;
	}
	
	.news-content {
		grid-column: 2 / 3;
		padding: 0;
	}
	
	.news-content .additional-media {
		margin-bottom: 0;
	}
	
}

@media all and (min-width: 667px) {
	
	.logo {
		flex: 0 0 13.8em;
		background: transparent;
	}
	
	.gallery-hero__item::before {
		z-index: 1;
	}
	
	.main-navigation__subnav {
		min-width: 33ch;
		height: auto;
		top: 0;
		padding: 3em 0 1em 0;
		left: 0;
		position: absolute;
	}
	
	.nav-item:nth-child(4) .main-navigation__subnav {
		min-width: 25ch;
	}
	
	.nav-item:nth-child(5) .main-navigation__subnav {
		min-width: 18ch;
	}
	
	.nav-item__subnav .nav-item__link {
		display: flex;
	}
	
	.nav-item--mobile-only {
		display: none;
	}
	
	.nav-item:hover > .main-navigation__subnav {
		visibility: visible;
		transform: translateY(0);
		background: linear-gradient(to bottom, #fff, #f8f8f8 79px, #efefef);
		transition: background-color .15s ease-out;
	}

	.nav-item:focus-within > .main-navigation__subnav {
		visibility: visible;
		transform: translateY(0);
		background: linear-gradient(to bottom, #fff, #f8f8f8 79px, #efefef);
		transition: background-color .15s ease-out;
	}
	
	.nav-item:hover > .main-navigation__subnav li {
		opacity: 1;
		transform: translateY(0);
		transition: opacity .3s ease-in var(--delay), transform .2s ease-in var(--delay);
	}
	
	.nav-item:focus-within > .main-navigation__subnav li {
		opacity: 1;
		transform: translateY(0);
		transition: opacity .3s ease-in var(--delay), transform .2s ease-in var(--delay);
	}

	.main-navigation--scrolled .nav-item:hover > .main-navigation__subnav,
	.main-navigation--no-header .nav-item:hover > .main-navigation__subnav {
		background-color: white;
	}
	
	.main-navigation--scrolled .nav-item:focus-within > .main-navigation__subnav,
	.main-navigation--scrolled .nav-item:focus-within > .main-navigation__subnav {
		background-color: white;
	}
		
	.main-navigation__trigger {
		display: none;
	}
	
	.main-navigation {
		position: fixed;
		background-color: transparent;
	}
	
	.main-navigation--no-header {
		position: sticky;
	}
	
	.main-navigation--scrolled {
		background-color: white;
	}
	
	.main-navigation:hover {
		background: linear-gradient(to bottom, #fff, #f8f8f8 79px, #efefef);
		transition: background-color .15s ease-out;
	}

	.main-navigation:focus-within {
		background: linear-gradient(to bottom, #fff, #f8f8f8 79px, #efefef);
	}
	
	.nav-item {
		font-size: 1em;
		padding: 1.02em 1rem 0.58rem 1rem;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		visibility: visible;
		opacity: 1;
		transform: none;
	}
	
	.nav-item__subnav {
		font-weight: normal;
		justify-content: flex-start;
		padding: 0 1em 0 1em;
		margin: 0;
		line-height: 1.5;
	}
	
	.nav-item .nav-item__subnav:hover .nav-item__link {
		font-weight: bold;
	}

	.nav-item .nav-item__subnav:focus-within .nav-item__link {
		font-weight: bold;
	}
	
	.main-navigation__nav {
		transform: translateX(0);
		position: static;
		margin: 0;
		padding: 0;
		height: auto;
		align-self: stretch;
		width: auto;
		display: flex;
		overflow: visible;
		background: transparent;
		margin-left: 1.75em;
		justify-content: flex-start;
		font-size: 14px;
	}
	
	.main-navigation__nav > .nav-item > .nav-item__link {
		color: white;
		text-shadow: 1px 1px 1px rgba(0,0,0,0.1);
	}
	
	.main-navigation--no-header .main-navigation__nav > .nav-item > .nav-item__link,
	.main-navigation--scrolled .main-navigation__nav > .nav-item > .nav-item__link,
	.main-navigation:hover .main-navigation__nav > .nav-item > .nav-item__link {
		color: black;
		text-shadow: none;
	}

	.main-navigation:focus-within .main-navigation__nav > .nav-item > .nav-item__link {
		color: black;
		text-shadow: none;
	}
	
	.main-navigation:hover .main-navigation__nav > .nav-item:hover > .nav-item__link,
	.nav-item__link:focus {
		color: #b01c2e;
		color: var(--brand-red);
	}
	
	.main-navigation--no-header .main-navigation__nav > .nav-item .nav-item__link.nav-item__link--active-page,
	.main-navigation--scrolled .main-navigation__nav > .nav-item .nav-item__link.nav-item__link--active-page,
	.main-navigation:hover .main-navigation__nav > .nav-item .nav-item__link.nav-item__link--active-page {
		font-weight: bold;
		color: #b01c2e;
		color: var(--brand-red);
	}
	
	.main-navigation > .container {
		width: auto;
		justify-content: center;
		padding: 0.25em 1em;
	}
}

@media all and (min-width: 800px) {
	
	.hero .hero__text--carousel-item .section-subhead {
		font-size: 400%;
	}
	
	.subsection .career-card__title {
		font-size: 150%;
	}
	
	.career-card__action {
		grid-row: 1 / 3;
	}
	
	.hero .section-header {
		font-size: 5em;
	}
	
	.hero .section-subhead {
		font-size: 2em;
	}
	
	.subsection > .section-header {
		font-size: 3.5em;
	}
	
	.subsection > .section-header--cares {
		font-size: 1.5em;
	}
	
	.grid--three {
		grid-template-columns: repeat(3, 1fr);
	}
	
	.grid--leadership > .person,
	.grid--leadership > .person:last-child {
		grid-column: auto;
	}
	
	html {
		font-size: 14px;
	}
	
	.section-body--first {
		padding: 1em 4em;
	}
	
	.five-values {
		display: flex;
		flex-flow: wrap;
		justify-content: space-evenly;
	}
	
	.five-values__value {
		min-width: calc(50% - 1em);
		background-position: top center;
	}
	
	.value--title {
		align-self: flex-start;
	}
	
	.promises .value--title {
		align-self: center;
	}
	
	.five-values__value {
		margin: 0.5em;
		flex: 1 0 0;
	}
	
	.gallery-hero,
	.gallery-hero__item {
		max-height: 800px;
		max-height: calc(100vh - 12rem);
		overflow: hidden;
	}
	
	.gallery-hero__image {
		max-height: 800px;
		max-height: calc(100vh - 12rem);
		object-fit: cover;
		object-position: center;
	}
	
	.gallery-hero__item--bottom-anchor .gallery-hero__image {
		object-position: bottom;
	}
	
	.hero--has-vimeo::before {
		content: none;
	}
	
	.gallery-hero__title,
	.hero__text {
		z-index: 2;
	}
	
	.footer > .container {
		text-align: left;
		display: grid;
		grid-template-columns: 20ch 1fr 18em;
		grid-gap: 1em;
		gap: 1em;
	}
	
	.footer__links .btn {
		width: 90%;
	}
	
	.footer__story {
		margin: 0 1em 0 0;
		max-width: 100%;
	}
	
	.footer__address {
		flex-direction: column;
		margin: 0;
	}
	
	.footer__story p {
		margin-bottom: 0;
	}
	
	.footer__navigation li:last-child {
		margin-bottom: 0;
	}
	
	.main-navigation__nav {
		font-size: inherit;
	}
}

@media all and (min-width: 1000px) {
	
	html {
		font-size: 16px;
	}
	
	.nav-item__subnav {
		margin: 0;
	}
	
	.main-navigation > .container {
		padding: 0.25em 2em;
	}
	
	.news-content {
		display: flex;
		flex-direction: column;
	}
	
	.news-content p {
		margin-top: 0;
		margin-left: 0;
	}
	
	.news-content .additional-media {
		margin-top: auto;
	}
	
	.grid--benefits .rollup > ul {
		font-size: 72%;
	}
	
	.grid--four {
		grid-template-columns: repeat(4, 1fr);
	}
	
	.news-image img {
		display: block;
	}
	
	.additional-media {
		grid-template-columns: repeat(4, 1fr);
	}

}

@media all and (min-width: 1100px) {
	.grid--benefits .rollup > ul {
		font-size: 75%;
	}
}

@media all and (min-width: 1200px) {
	.grid--benefits .rollup > ul {
		font-size: 80%;
	}
}

@media all and (min-width: 1400px) {
	.grid--benefits .rollup > ul {
		font-size: 90%;
	}
}

/*
@media all and (min-width: 800px) {
	.vimeo-wrapper {
		padding-bottom: calc((8/16) * 100vh + 152px - 0.5em);
	}
} */

@media all and (max-aspect-ratio: 10/9) {
	.vimeo-wrapper {
		padding-bottom: 56.25%;
	}
}

@media all and (min-width: 1400px) and (min-height: 1070px) {
	
	.vimeo-wrapper {
		padding-bottom: 56.25%;
	}
}

@media all and (min-width: 1200px) {	
	.five-values__value {
		min-width: initial;
		background-position: bottom center;
	}
	
	.rollup p {
		padding: 0 1.5em;
		margin: 1.25em 0;
		font-size: 1.25em;
	}
	
	.rollup li {
		font-size: 1em;
	}
	
	.footer__story-and-address {
		display: grid;
		grid-template-columns: minmax(min-content, 42ch) minmax(min-content, 38ch);
	}
	
	.footer__address {
		flex-direction: column;
		margin: 0 4em;
	}
	
	.footer__story {
		max-width: 40ch;
	}
}

@media all and (min-width: 1290px) {
	
	.gallery-thumbnail__item {
		max-width: calc(322px - 0.75em);
	}
}

@media all and (min-width: 1432px) {
	
	.grid {
		padding-left: 0;
		padding-right: 0;
	}
}

@media screen and (prefers-reduced-motion: reduce) {
	// Need to reduce all hover effect, remove navigation effect
}