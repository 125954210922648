.dialog {
	visibility: hidden;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 10;
	background: rgba(0,0,0,0.4);
}

.dialog.dialog--open {
	visibility: visible;
	z-index: 10;
}

.dialog__media {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 50%;
	left: 50%;
	height: 100vh;
	max-height: 75vw;
	width: 100vw;
	max-width: 100vw;
	transform: translate(-50%, -50%);
	padding: 5rem 5.5rem 6rem;
}

.dialog__media img {
	display: block;
}

.dialog--btn {
	visibility: hidden;
	position: absolute;
	z-index: 20;
	background: rgba(0,0,0,0.4);
	border: 0;
	border-radius: 100%;
	padding: 0.35em;
	cursor: pointer;
}

.dialog--btn:hover {
	background: rgba(0,0,0,0.7);
}

.dialog--btn svg {
	display: block;
	fill: #ddd;
	transform: scale(0.75);
}

.dialog--btn:hover svg {
	fill: #eee;
}

.dialog--close {
	right: 1em;
	top: 1em;
}

.dialog--next,
.dialog--previous {
	top: 50%;
	transform: translateY(-50%);
}

.dialog--next {
	right: 1em;
}

.dialog--previous {
	left: 1em;
}

.dialog--open .dialog--btn {
	visibility: visible;
}

.dialog--open .dialog--hidden {
	visibility: hidden;
}

[data-gallery-index-value="0"] .dialog--previous,
[data-gallery-last-item-value="true"] .dialog--next {
	visibility: hidden;
}

.dialog img,
.dialog iframe {
	visibility: hidden;
}


@keyframes spinner {
  to {transform: rotate(360deg);}
}

.dialog--open .dialog__media::before {
	content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -20px;
  border-radius: 50%;
  border-top: 4px solid var(--brand-red);
	border-right: 4px solid transparent;
  animation: spinner .6s linear infinite;
}

.dialog--open .dialog--loaded::before {
	content: none;
}

.dialog--open .dialog--loaded img {
	visibility: visible;
	width: auto;
	max-height: 100%;
	max-width: 100%;
}

.dialog--open .dialog--loaded iframe {
	visibility: visible;
	width: 100%;
	height: 100%;
	border: 0;
}